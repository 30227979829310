import Layout from "../Partials/Layout";
import { useHistory } from "react-router-dom";
import {SyncMiscData} from '../Partials/SendOfflineReceiptsWhenBackOnline'
import React, {useState} from 'react';
import { LoadingOverlay } from "@mantine/core";

export default function DashboardView() {
  const [isWorking, setIsWorking] = useState(false);
  const history = useHistory();
  return (
    <Layout title="Dashboard">
      <div className="w-full flex items-center justify-center p-4">
        <span className="font semibold">Welcome Back</span>
      </div>

      <div className="w-full grid grid-cols-2 gap-4 mt-8 relative">
        <LoadingOverlay visible={isWorking}/>
        {/*check savings balance*/}
        {/* <div
          className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
          onClick={() => history.push("/check/savings/balance")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <span className="text-center">Check Savings Balance</span>
        </div> */}

        {/*    check shares balance*/}
        {/* <div
          className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
          onClick={() => history.push("/check/shares/balance")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
          <span className="text-center">Check Shares Balance</span>
        </div> */}

        {/*    new receipt*/}
        <div
          className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
          onClick={() => history.push("/new/receipt")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
            />
          </svg>
          <span className="text-center">New Receipt</span>
        </div>

        {/* pending sync */}
        <div
          onClick={() => history.push("/pending/receipt/sync")}
          className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-10 h-10"
          >
            <path
              fillRule="evenodd"
              d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
              clipRule="evenodd"
            />
          </svg>

          <span className="text-center">Pending Sync</span>
        </div>

        {/* daily transaction summary*/}
        {/* <div
            className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
            onClick={() => history.push("/daily/trans")}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
            />
          </svg>
          <span className="text-center">Daily Trans</span>
        </div> */}


        {/* summary transaction*/}
        <div
            className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
            onClick={() => history.push("/summary/trans/view")}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
            />
          </svg>
          <span className="text-center">Summary Trans</span>
        </div>


                {/* daily transaction summary*/}
                <div
            className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
            onClick={() => history.push("/daily/trans")}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
            />
          </svg>
          <span className="text-center">Daily Trans</span>
        </div>


        {/* flush offline db*/}
        <div
            className="flex flex-col items-center justify-center space-y-2 bg-gray-50 rounded-lg p-4 shadow hover:bg-gray-100"
            onClick={() => reloadAccounts()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>
          <span className="text-center">Reload Accounts</span>
        </div>

      </div>
    </Layout>
  );

  async function reloadAccounts(){
    setIsWorking(true);
    const result = await SyncMiscData.flushDbAsync().finally(() => setIsWorking(false))

  }
}

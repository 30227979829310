import Layout from "../Partials/Layout";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  accountsCodesDbName,
  axiosGet,
  axiosPost,
  dbName,
  GetUsername,
  getUsername,
  moneyFormat,
  OP_BAL_Date,
  savingsCodesListDbName,
  sharesCodesListDbName,
  verifyCustomerAccountCode,
} from "../Partials/Constants";
import {
  TextInput,
  Select,
  Avatar,
  Button,
  NumberInput,
  Loader,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import PouchDb from "pouchdb";
const db = new PouchDb(dbName);

export default function NewReceiptView() {
  const [accountCode, setAccountCode] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [savingsCode, setSavingsCode] = useState("");
  const [sharesCode, setSharesCode] = useState("");
  const [loansCode, setLoansCode] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [isCheckingAccountCode, setIsCheckingAccountCode] = useState(false);
  const [savingsAmount, setSavingsAmount] = useState(0);
  const [sharesAmount, setSharesAcount] = useState(0);
  const [loansAmount, setLoansAmount] = useState(0);
  const [sharesCodeList, setSharesCodeList] = useState([]);
  const [savingsCodeList, setSavingsCodeList] = useState([]);
  const [accountCodeList, setAccountCodeList] = useState([]);
  const [manualReceiptNo, setManualReceiptNo] = useState('');

  const username = getUsername();

  useEffect(() => {
    getCodes();
  }, []);

  async function getCodes() {
    //set savings codes list
    let db = new PouchDb(savingsCodesListDbName);
    let docs = await db.allDocs({ include_docs: true, descending: true });
    if (docs) {
      for (const v of docs.rows) {
        setSavingsCodeList((prev) => [...prev, v?.doc?.SavingsCode]);
      }
    }

    //set shares codes
    let sharesDb = new PouchDb(sharesCodesListDbName);
    let sharesDocs = await sharesDb.allDocs({
      include_docs: true,
      descending: true,
    });
    if (sharesDocs) {
      for (const v of sharesDocs.rows) {
        setSharesCodeList((prev) => [...prev, v?.doc?.SharesCode]);
      }
    }

    //set account codes
    let accDb = new PouchDb(accountsCodesDbName);
    let accDocs = await accDb.allDocs({ include_docs: true, descending: true });
    if (accDocs) {
      let outputArr = [];
      // setAccountCodeList((prev) => [...prev, accDocs.rows.doc]);
      for (const v of accDocs.rows) {
        outputArr.push(v?.doc);
      }
      setAccountCodeList(outputArr);
    }
  }

  useEffect(() => {
    handleVerifyAccountCode();
  }, [accountCode]);

  async function handleVerifyAccountCode() {
    const result = accountCodeList.find((x) => x?.AccountCode == accountCode);
    if (result) {
      setCustomerName(result.AccountName);
    } else {
      setCustomerName("");
    }
    // const result = await verifyCustomerAccountCode(accountCode).finally(() => setIsCheckingAccountCode(false));
    // if (result && !result.AccountName) {
    //     setCustomerName("");
    // }
    // if (result && result.AccountName) setCustomerName(result.AccountName);
  }

  async function handleSaveReceipt() {
    if (!accountCode)
      return showNotification({
        message: "Account code is required",
      });
    if (savingsAmount && isNaN(savingsAmount))
      return showNotification({
        message: "Savings amount must be a valid number",
      });
    if (sharesAmount && isNaN(sharesAmount))
      return showNotification({
        message: "Shares amount must be a valid number",
      });

    //    if all passed
    setIsWorking(true);
    let payload = {
      AccountCode: accountCode,
      SavingsCode: savingsCode,
      SavingsDeposit: savingsAmount,
      SharesCode: sharesCode,
      SharesDeposit: sharesAmount,
      LoanCode: loansCode,
      LoanRepayment: loansAmount,
      Reference:manualReceiptNo,
      EnteredBy: GetUsername(),
    };
    if (!window.navigator.onLine) {
      await db.post(payload);
      showNotification({
        message:
          "You seem to be offline. Hence this record will be saved locally",
      });
      setSavingsAmount(0);
      setSharesAcount(0);
      setAccountCode("");
      // setSavingsCode("");
      // setSharesCode("");
      setIsWorking(false);
      return;
    }
    const result = await axiosPost("post/new/receipt", payload)
      .catch((err) =>
        showNotification({
          message: err,
        })
      )
      .finally(() => setIsWorking(false));
    showNotification({
      message: "Receipt saved successfully",
    });
    setSavingsAmount(0);
    setSharesAcount(0);
    setAccountCode("");
    // setSavingsCode("");
    // setSharesCode("");
  }

  return (
    <Layout showBackButton={true} title="New Receipt">
      {/*customer details*/}
      {/*<span>Customer Details</span>*/}
      <div className="flex items-center space-x-2 bg-gray-200 px-4 py-4 rounded-xl">
        <Avatar radius={"md"} size={60} />
        <div className="flex flex-col w-full space-y-1">
          <span className="font-medium">{customerName || "N/A"}</span>
          <TextInput
            required={true}
            rightSection={
              isCheckingAccountCode && <Loader variant="dots" size="xs" />
            }
            placeholder="Enter account code"
            value={accountCode}
            inputMode="numeric"
            onChange={(e) => setAccountCode(e.target.value)}
            variant={"filled"}
          />
        </div>
      </div>

      {/* manual receipt no */}
      <div className="mt-6 border rounded-xl p-2">
      <TextInput
            variant={"filled"}
            size={"lg"}
            placeholder="Manual Receipt No"
            label="Manual Receipt No"
            value={manualReceiptNo}
            onChange={(e) => setManualReceiptNo(e.target.value)}
          />
      </div>

      {/*select savings code, shares code, loans code*/}
      <div className="mt-6 border rounded-xl p-2">
        <div className="flex flex-col space-y-4 w-full">
          <Select
            data={savingsCodeList}
            variant={"filled"}
            size={"lg"}
            placeholder="Select savings code"
            label="Select savings code"
            value={savingsCode}
            onChange={(e) => setSavingsCode(e)}
          />
          <Select
            data={sharesCodeList || []}
            variant={"filled"}
            size={"lg"}
            placeholder="Select shares code"
            label="Select shares code"
            value={sharesCode}
            onChange={(e) => setSharesCode(e)}
          />
        </div>
      </div>

      {/*enter balances*/}
      <div className="mt-6 border rounded-xl p-2">
        <div className="flex flex-col space-y-4 w-full">
          <NumberInput
            variant={"filled"}
            size={"lg"}
            placeholder="Enter savings amount"
            label="Enter savings amount"
            value={savingsAmount}
            step={0.05}
            precision={1}
            onChange={(e) => setSavingsAmount(e)}
            disabled={savingsCode === ""}
          />
          <NumberInput
            variant={"filled"}
            size={"lg"}
            placeholder="Enter shares amount"
            label="Enter shares amount"
            value={sharesAmount}
            step={0.05}
            precision={1}
            onChange={(e) => setSharesAcount(e)}
            disabled={sharesCode === ""}
          />
        </div>
      </div>

      {/*    save button*/}
      <div className="shadow rounded-lg p-2 flex items-center justify-center w-full mt-4">
        <Button
          onClick={() => handleSaveReceipt()}
          size={"lg"}
          fullWidth={true}
          loading={isWorking}
        >
          Save
        </Button>
      </div>
    </Layout>
  );
}

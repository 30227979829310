import Layout from "../Partials/Layout";
import {useState, useEffect} from "react";
import useSWR from "swr";
import {
    axiosGet,
    axiosPost,
    moneyFormat,
    OP_BAL_Date,
    verifyCustomerAccountCode,
    savingsCodesListDbName,
    dbName, OfflineSyncStatusStore,
} from "../Partials/Constants";
import {TextInput, Select, Avatar, Button, LoadingOverlay} from "@mantine/core";
import PouchDb from "pouchdb";
import {showNotification} from "@mantine/notifications";
import {useRecoilState} from "recoil";

const db = new PouchDb(dbName);

export default function PendingReceiptsSyncView() {
    const [isWorking, setIsWorking] = useState(false);
    const [pendingExist, setPendingExist] = useState(false);
    const [count, setCount] = useState(0);
    const [syncStatus, setSyncStatus] = useRecoilState(OfflineSyncStatusStore);
    //const {data: savingsCodes} = useSWR("get/savings/type", axiosGet);

    useEffect(() => {
        checkPending();
    }, []);

    useEffect(() => {
        setInterval(() => {
            checkPending();
        }, 1000);
    },)

    useEffect(() => {
        checkPending().then(r => {
        });
    }, [syncStatus]);

    async function checkPending() {
        const result = await db.info();
        setCount(result?.doc_count);
    }

    async function handlePush() {
        if (!window.navigator.onLine)
            return showNotification({
                message: "You need to be online to synchronize receipts",
            });
        setIsWorking(true);
        let docs = await db.allDocs({include_docs: true, descending: true});
        if (docs) {
            for (const v of docs.rows) {
                //const _i = docs.indexOf(v);
                let payload = {
                    AccountCode: v?.doc?.AccountCode,
                    SavingsCode: v?.doc?.SavingsCode,
                    SavingsDeposit: v?.doc?.SavingsDeposit,
                    SharesCode: v?.doc?.sharesCode,
                    SharesDeposit: v?.doc?.SharesDeposit,
                    LoanCode: v?.doc?.LoanCode,
                    LoanRepayment: v?.doc?.LoanRepayment,
                    EnteredBy: v?.doc?.EnteredBy,
                };
                const result = await axiosPost("post/new/receipt", payload);
                if (result) db.remove(v?.doc);
            }
            showNotification({
                message: "Receipt(s) sync successfully",
            });
            setCount(0);
        }
    }

    if (count > 0)
        return (
            <Layout showBackButton={true} title="Pending Sync">
                <div className="relative p-6">
                    <div className="flex flex-col space-y-4">
                        <span className="font-semibold text-xl">{count} data pending sync</span>
                        <span>
              <b>Note</b> you need to be online to be able to synchronize
              offline receipts
            </span>
                        <Button loading={isWorking} onClick={() => handlePush()}>
                            Click here to synchronize
                        </Button>
                    </div>
                    <LoadingOverlay visible={syncStatus}/>
                </div>
            </Layout>
        );

    return (
        <Layout showBackButton={true} title="Pending Sync">
            <div className="flex flex-col mt-4">No data to synchronize for now.</div>
        </Layout>
    );
}

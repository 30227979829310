import React, { useState, useEffect } from "react";
import PouchDB from "pouchdb";
import {
  accountsCodesDbName,
  axiosGet,
  axiosPost,
  dbName, OfflineSyncStatusStore,
  savingsCodesListDbName,
  sharesCodesListDbName,
} from "./Constants";
import {useRecoilState} from "recoil";

const db = new PouchDB(dbName);
const savingsListDb = new PouchDB(savingsCodesListDbName);
const sharesListDb = new PouchDB(sharesCodesListDbName);
const accountsDb = new PouchDB(accountsCodesDbName);

export default function SendOfflineReceiptsWhenBackOnline() {
  const [syncStatus, setSyncStatus] = useRecoilState(OfflineSyncStatusStore);

  useEffect(() => {
    window.addEventListener("online", sendOfflineReceipts);
  }, []);

  async function sendOfflineReceipts() {
    if (!window.navigator.onLine) return false;

    let docs = await db.allDocs({ include_docs: true, descending: true });
    if (docs) {

      for (const v of docs.rows) {
        //const _i = docs.indexOf(v);
        setSyncStatus(true);
        let payload = {
          AccountCode: v?.doc?.AccountCode,
          SavingsCode: v?.doc?.SavingsCode,
          SavingsDeposit: v?.doc?.SavingsDeposit,
          SharesCode: v?.doc?.SharesCode,
          SharesDeposit: v?.doc?.SharesDeposit,
          LoanCode: v?.doc?.LoanCode,
          LoanRepayment: v?.doc?.LoanRepayment,
          EnteredBy: v?.doc?.EnteredBy,
        };

        const result = await axiosPost("post/new/receipt", payload).finally(() =>    setSyncStatus(false));
        if (result) db.remove(v?.doc);
      }

    }
  }

  return <div></div>;
}

export const SyncMiscData = {
  syncSavingsCode: async function () {
    const result = await axiosGet("get/savings/type");
    let output = [];
    result &&
      result.forEach((item, index) => {
        let payload = {
          //_id: item.SavingsCode,
          SavingsCode: item.value,
        };
        output.push(payload);
      });

    savingsListDb.destroy(savingsCodesListDbName).then(() => {
      let newDb = new PouchDB(savingsCodesListDbName);
      newDb.bulkDocs(output).then(() => {});
    });
  },

  syncSharesCode: async function () {
    const result = await axiosGet("get/shares/type");
    let output = [];
    result &&
      result.forEach((item, index) => {
        let payload = {
          // _id: item.SharesCode,
          SharesCode: item.value,
        };
        output.push(payload);
      });
    sharesListDb.destroy(sharesCodesListDbName).then(() => {
      let newDb = new PouchDB(sharesCodesListDbName);
      newDb.bulkDocs(output).then(() => {});
    });
  },

  syncAccountsCode: async function () {
    const result = await axiosGet("account/codes/lov");
    let output = [];
    result &&
      result.forEach((item, index) => {
        let payload = {
          //_id: item.value,
          AccountCode: item.value,
          AccountName: item.label,
        };
        output.push(payload);
      });
    accountsDb.destroy(accountsCodesDbName).then(() => {
      let newDb = new PouchDB(accountsCodesDbName);
      newDb.bulkDocs(output).then(() => {});
    });
  },

  flushDbAsync: async function () {
    await this.syncAccountsCode();
    await this.syncSavingsCode();
    await this.syncSharesCode();
  },
};

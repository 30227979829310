import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NotificationsProvider } from '@mantine/notifications';
import {RecoilRoot} from "recoil";


const rootElement = document.getElementById("root");
ReactDOM.render(
    <StrictMode>
            <BrowserRouter>
            <NotificationsProvider position="top-center" limit={1} autoClose={5000} zIndex={2077}>
                <RecoilRoot>
                <App/>
                </RecoilRoot>
                </NotificationsProvider>
            </BrowserRouter>
    </StrictMode>,
    rootElement
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <App/>
//         </BrowserRouter>
//     </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {AppShell, Navbar, Header, ActionIcon, Menu, Divider} from "@mantine/core";
import {useHistory} from "react-router-dom";

export default function Layout({title, showBackButton = false, children}) {
    const history = useHistory();

    return <AppShell
        fixed={true}
        padding="0"
        header={<Header height={60} p="xs"
                        className="bg-blue-700 text-white flex items-center justify-between space-x-4">

            <div>
                {showBackButton && <ActionIcon onClick={() => history.goBack()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white stroke-current" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
                    </svg>
                </ActionIcon>}
            </div>
            <span className="font-bold text-xl">{title}</span>
            <div>
                <Menu control={<ActionIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-white stroke-current" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
                    </svg>
                </ActionIcon>}>
                    <Menu.Item>Profile</Menu.Item>
                    <Divider/>
                    <Menu.Item color="red" onClick={() => logOut()}>Log out</Menu.Item>
                </Menu>
            </div>
        </Header>}
    >
        {children}
    </AppShell>

    function logOut(){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push('/')
    }
}
import { useState } from "react";
import { TextInput, PasswordInput, Button } from "@mantine/core";
import { axiosPost, HashPassword } from "../Partials/Constants";
import { useHistory } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

export default function LoginView() {
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [working, setWorking] = useState(false);
  const history = useHistory();

  async function handleLogin() {
    if (!username)
      return showNotification({
        message: "Username is required",
      });
    if (!pwd)
      return showNotification({
        message: "Password is required",
      });

    setWorking(true);
    let payload = {
      userId: username,
      password: HashPassword(pwd),
    };
    const result = await axiosPost("mobile/login", payload)
      .catch((err) =>
        showNotification({
          message: err,
        })
      )
      .finally(() => setWorking(false));
    if (result) {
      localStorage.setItem("user", result.user);
      localStorage.setItem("token", result.token);
      localStorage.setItem("op_date", result.opDate);
      history.replace("/dashboard");
    } else {
        showNotification({
            message: "Unable to login",
          });
    }
  }

  return (
    <div className="w-screen h-screen bg-gray-50 flex items-center flex-col p-6 justify-center">
      <span>Welcome Back, Login</span>

      <div className="flex flex-col space-y-3 w-full mt-12">
        <TextInput
          placeholder="Enter username"
          label="Enter username"
          variant={"filled"}
          size={"lg"}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <PasswordInput
          value={pwd}
          onChange={(e) => setPwd(e.currentTarget.value)}
          placeholder="Enter password"
          label="Enter password"
          variant={"filled"}
          size={"lg"}
        />
      </div>
      <div className="mt-3 w-full">
        <Button
          fullWidth={true}
          size={"lg"}
          loading={working}
          onClick={() => handleLogin()}
        >
          Log in
        </Button>
      </div>
    </div>
  );
}

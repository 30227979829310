import {getUsername} from "./Partials/Constants";
import React, {useState, useEffect, useCallback} from "react";
import {Switch, Route} from "react-router-dom";
import LoadingView from "./Views/LoadingView";
import LoginView from "./Views/LoginView";
import DashboardView from "./Views/DashboardView";
import CheckSavingsBalanceView from "./Views/CheckSavingsBalanceView";
import CheckSharesBalanceView from "./Views/CheckSharesBalanceView";
import NewReceiptView from "./Views/NewReceiptView";
import SendOfflineReceiptsWhenBackOnline, {SyncMiscData} from "./Partials/SendOfflineReceiptsWhenBackOnline";
import PendingReceiptsSyncView from "./Views/PendingReceiptsSyncView";
import DailyTransSum from "./Views/DailyTransSum";
import SummaryTrans from "./Views/SummaryTrans";

function App() {

    useEffect(() => {
        window.addEventListener('online', syncData)
        syncData()
    }, []);

    async function syncData() {
        if (!window.navigator.onLine) return false;
        await SyncMiscData.syncSavingsCode();
        await SyncMiscData.syncSharesCode();
        await SyncMiscData.syncAccountsCode();
    }

    return (
        <div>
            <SendOfflineReceiptsWhenBackOnline/>
            <Switch>
                <Route exact path="/" component={LoadingView}/>
                <Route exact path="/login" component={LoginView}/>
                <Route exact path="/dashboard" component={DashboardView}/>
                <Route exact path="/check/savings/balance" component={CheckSavingsBalanceView}/>
                <Route exact path="/check/shares/balance" component={CheckSharesBalanceView}/>
                <Route exact path="/new/receipt" component={NewReceiptView}/>
                <Route exact path="/pending/receipt/sync" component={PendingReceiptsSyncView}/>
                <Route exact path="/daily/trans" component={DailyTransSum}/>
                <Route exact path="/summary/trans/view" component={SummaryTrans}/>
            </Switch>
        </div>
    );
}

export default App

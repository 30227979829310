import Layout from "../Partials/Layout";
import { useState, useEffect } from "react";
import useSWR from "swr";
import {
  axiosGet,
  axiosPost,
  moneyFormat,
  OP_BAL_Date,
  verifyCustomerAccountCode,
  savingsCodesListDbName,
  dbName,
} from "../Partials/Constants";
import { TextInput, Select, Avatar, Button } from "@mantine/core";
import PouchDb from "pouchdb";
import { showNotification } from "@mantine/notifications";

export default function CheckSavingsBalanceView() {
  const [accountCode, setAccountCode] = useState("");
  const [customerBalance, setCustomerBalance] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [savingsCodes, setSavingsCodes] = useState([]);

  //const {data: savingsCodes} = useSWR("get/savings/type", axiosGet);

  useEffect(() => {
    getCodes();
  }, []);

  async function getCodes() {
    let output = [];
    let db = new PouchDb(savingsCodesListDbName);
    let docs = await db.allDocs({ include_docs: true, descending: true });
    if (docs) {
      for (const v of docs.rows) {
        output.push(v?.doc?.SavingsCode);
      }
      setSavingsCodes(output);
    }
  }

  useEffect(() => {
    if (!accountCode) return;
    getCustomerName();
  }, [accountCode]);

  async function getCustomerName() {
    const result = await verifyCustomerAccountCode(accountCode);
    if (result) setCustomerName(result?.AccountName);
  }

  // function formatSavingsCodes() {
  //     let output = [];
  //     savingsCodes && savingsCodes.forEach((item, index) => {
  //         output.push(item.SavingsCode)
  //     });
  //     return output;
  // }

  async function handleCheckBalance() {
    if (!accountCode) return  showNotification({
        message: "Account code is required",
      });;
    setIsWorking(true);
    //verify account code
    const result = await verifyCustomerAccountCode(accountCode).finally(() =>
      setIsWorking(false)
    );

    if (result && !result.AccountName) {
      setCustomerBalance(null);
      setCustomerName("");
      showNotification({
        message: "Customer code seem incorrect",
      });
    }
    if (result) setCustomerName(result.AccountName);
    setIsWorking(true);
    let payload = {
      AccountCode: accountCode,
      SavingsCode: selectedCode,
      OpeningBalanceDate: OP_BAL_Date,
      TransactionDate: new Date(),
    };
    const check = await axiosPost("savings", payload)
      .catch((err) =>
        showNotification({
          message: err,
        })
      )
      .finally(() => setIsWorking(false));
    setCustomerBalance(check);
  }

  return (
    <Layout showBackButton={true} title="Check Savings Balance">
      <div className="flex flex-col mt-4">
        <div className="flex items-center space-x-2 bg-gray-200 px-4 py-6 rounded-xl">
          <Avatar radius={"xl"} />
          <span className="font-medium">{customerName || "N/A"}</span>
        </div>

        <div className="flex flex-col space-y-4 mt-8">
          <TextInput
            required={true}
            value={accountCode}
            onChange={(e) => setAccountCode(e.target.value)}
            placeholder="Enter customer account code"
            size={"lg"}
            variant={"filled"}
            label="Enter customer account code"
          />

          <Select
            required={true}
            data={savingsCodes}
            value={selectedCode}
            onChange={(e) => setSelectedCode(e)}
            label="Select savings code type"
            placeholder="Select savings code type"
            size={"lg"}
            variant={"filled"}
          />

          <Button
            onClick={() => handleCheckBalance()}
            size={"lg"}
            loading={isWorking}
          >
            Check Balance
          </Button>
        </div>
      </div>

      {customerBalance !== null && (
        <div className="mt-8 w-full bg-yellow-300 rounded-lg shadow flex items-center justify-center flex-col text-center text-2xl p-6">
          <span className="font-medium text-center">Account Balance is:</span>
          <b className="text-4xl text-center">{moneyFormat(customerBalance)}</b>
        </div>
      )}
    </Layout>
  );
}

import React from 'react';
import Layout from "../Partials/Layout";
import {useEffect, useState} from "react";
import useSWR from 'swr';
import {
    accountsCodesDbName,
    axiosGet,
    axiosPost, dbName, GetUsername,
    getUsername,
    moneyFormat,
    OP_BAL_Date, savingsCodesListDbName, sharesCodesListDbName,
    verifyCustomerAccountCode
} from "../Partials/Constants";
import moment from 'moment';


export default function DailyTransSum() {
    const userId = GetUsername();
    const {data = [], mutate, error} = useSWR(userId ? `daily/trans/sum?userId=${userId}` : null, axiosGet)


    if (data && !error && data?.Data)
        return <Layout showBackButton={true} title="Daily Transaction Sum">
            <div className="grid gap-4 grid-cols-1 pb-24 px-2 pt-2">
                {data?.Data?.map((v, _) => <div key={v?.ReceiptNo}><EachRow data={v}/></div>)}
            </div>
        {/*sums*/}
            <div className="fixed bottom-0 p-4 bg-gray-200 shadow-lg flex items-center space-x-4 justify-between w-full">
                <span>Savings Total: <b>{moneyFormat(data?.SumSavings)}</b></span>
                <span>Shares Total: <b>{moneyFormat(data?.SumShares)}</b></span>
            </div>
        </Layout>

    if (!data && !error) return <Layout showBackButton={true} title="Daily Transaction Sum">
        <div>loading</div>
    </Layout>

    return <Layout showBackButton={true} title="Daily Transaction Sum">
        <div>No transaction to show</div>
    </Layout>
}

function EachRow({data: v}) {
    return <div className="flex flex-col space-y-4 border-b-4 border-gray-200 pb-4">
       <div className="flex items-center space-x-2">
           <b>Date:</b>
           <span className="text-sm">{moment(v?.Dated).format("ll")}</span>
       </div>

        <div className="flex items-center space-x-2">
            <b>Account Code:</b>
            <span className="text-sm">{v?.AccountCode}</span>
        </div>


        {/*savings*/}
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
        <div className="flex items-center space-x-2">
            <b className="text-sm">Savings:</b>
            <span className="text-sm">{v?.SavingsCode}</span>
        </div>
        <div className="flex items-center space-x-2">
            <b className="text-sm">Amount:</b>
            <span className="text-sm">GHC{moneyFormat(v?.SavingsDeposit)}</span>
        </div>
            </div>



    {/*    shares*/}
        <div className="grid grid-cols-2">
            <div className="flex flex-col">
                <div className="flex items-center space-x-2">
                    <b className="text-sm">Shares:</b>
                    <span className="text-sm">{v?.SharesCode}</span>
                </div>
                <div className="flex items-center space-x-2">
                    <b className="text-sm">Amount:</b>
                    <span className="text-sm">GHC{moneyFormat(v?.SharesDeposit)}</span>
                </div>
            </div>
        </div>
        </div>
    </div>
}
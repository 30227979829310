import {Loader} from '@mantine/core';
import {getUsername} from "../Partials/Constants";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";

export default function LoadingView() {
    const localUsername = getUsername();
    const history = useHistory();

    useEffect(() => {
        handleLoading();
    }, []);

    async function handleLoading() {
        setTimeout(() => {
            if (!localUsername) history.replace("/login");
            if(localUsername) history.replace("/dashboard")
        }, 1000);

    }
    return <div className="w-screen h-screen bg-gray-50 flex items-center flex-col p-6 justify-center space-y-2">
        <Loader/>
        <span>Loading... Please wait</span>
    </div>
}
import React from 'react';
import Layout from "../Partials/Layout";
import {useEffect, useState} from "react";
import useSWR from 'swr';
import {
    accountsCodesDbName,
    axiosGet,
    axiosPost, dbName, GetUsername,
    getUsername,
    moneyFormat,
    OP_BAL_Date, savingsCodesListDbName, sharesCodesListDbName,
    verifyCustomerAccountCode
} from "../Partials/Constants";
import moment from 'moment';
import {Tabs} from '@mantine/core';

export default function SummaryTrans() {
    const userId = GetUsername();
    const {data = [], mutate, error} = useSWR(userId ? `summary/trans?userId=${userId}` : null, axiosGet)


    if (data && !error)
        return <Layout showBackButton={true} title="Daily Transaction Sum">
            <Tabs grow={true} variant={"default"}>
                <Tabs.Tab label="Receipt Summary">
                    <div className="grid gap-4 grid-cols-1  px-2 pt-2">
                        {data?.SavingsData?.map((v, _) => <div key={v?.SavingsCode}><EachRow
                            code={v?.SavingsCode}
                            amount={v?.TotalSavingsAmount}
                        /></div>)}
                    </div>
                    <div className="grid gap-4 grid-cols-1 px-2 pt-2">
                    {data?.SharesData?.map((v, _) => <div key={v?.SharesCode}><EachRow
                        data={v}
                        code={v?.SharesCode}
                        amount={v?.TotalSharesAmount}
                        title="Shares Code"/>
                    </div>)}
                    </div>
                    <div className="flex items-center justify-center flex-col p-4">
                        <span className="text-lg font-bold">Grand Total: </span>
                        <b className="text-2xl">{moneyFormat(data?.SumSavings + data?.SumShares)}</b>
                    </div>
                </Tabs.Tab>
                {/*<Tabs.Tab label="Shares Summary">*/}
                {/*    <div className="grid gap-4 grid-cols-1 px-2 pt-2">*/}
                {/*        {data?.SharesData?.map((v, _) => <div key={v?.SharesCode}><EachRow*/}
                {/*            data={v}*/}
                {/*            code={v?.SharesCode}*/}
                {/*            amount={v?.TotalSharesAmount}*/}
                {/*            title="Shares Code"/>*/}
                {/*        </div>)}*/}
                {/*    </div>*/}
                {/*    <div className="flex items-center justify-center flex-col p-4">*/}
                {/*        <span className="text-lg font-bold">Shares Total: </span>*/}
                {/*        <b className="text-2xl">{moneyFormat(data?.SumShares)}</b>*/}
                {/*    </div>*/}
                {/*</Tabs.Tab>*/}
            </Tabs>
        </Layout>

    if (!data && !error) return <Layout showBackButton={true} title="Daily Transaction Sum">
        <div>loading</div>
    </Layout>

    return <Layout showBackButton={true} title="Daily Transaction Sum">
        <div>No transaction to show</div>
    </Layout>
}

function EachRow({title = "Savings Code", code, amount}) {
    return <div className="flex flex-col space-y-4 border-b-4 border-gray-200 pb-4">
        <div className="flex flex-col">
            <div className="flex items-center space-x-2">
                <b className="text-sm">{title}</b>
                <span className="text-sm">{code}</span>
            </div>
            <div className="flex items-center space-x-2">
                <b className="text-sm">Amount:</b>
                <span className="text-sm">GHC{moneyFormat(amount)}</span>
            </div>
        </div>
    </div>
}